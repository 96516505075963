@import '~antd/dist/antd.css';

.reset-password-form {
  text-align: left;
}

.send-code-button {
  width: 100%;
}

.error-text {
  margin-bottom: 20px;
}
