body {
  overscroll-behavior: contain;
}

.body-detail {
  -ms-overflow-style: none;
}

.slide-detail {
  overflow: hidden;
  -ms-overflow-style: scrollbar;

  .slide-header {
    width: 100%;
    padding: 10px 20px;
    background: #f5f5f5;
    @media only screen and (max-device-width: 1024px) and (min-device-width: 768px) {
      margin: 0;
    }
  }
}

p {
  margin-bottom: 0;
}

.ant-input {
  border: 1px solid #aaaaaa;

  &:hover {
    border: 1px solid #aaaaaa !important;
  }
}

.ant-card {
  font-size: 16px;
}

.card-container {
  .ant-card-body {
    padding: 10px 20px;
  }
}

.has-error {
  .ant-input {
    border-color: #f5222d !important;
  }

  .ant-form-explain {
    padding-top: 5px;
  }
}

.card-header {

  .slide-info {
    p {
      margin-bottom: 0;
    }

    .ant-form-item-required {
      &::before {
        content: '';
        margin-right: 0;
      }

      &::after {
        display: inline-block;
        color: #f5222d;
        margin-left: 4px;
        font-size: 14px;
        font-family: SimSun, sans-serif;
        line-height: 1;
        content: '*';
      }
    }

    .slide-name {
      border: 1px solid rgba(85, 85, 85, 0.5);
      border-radius: 4px;
      padding: 10px;
      margin-right: 20px;
      margin-left: 10px;
      width: 300px;
    }

    .slide-date {
      font-size: 12px;
    }
  }

  .btn-save {
    background: #2f80ed;
    width: 140px;
    padding: 10px;
    height: auto;

    &:disabled {
      background: #f5f5f5;
    }
  }
}
.preview-canvas{
  width: 100%;
  height: 100%;
  background-color: #fff;
}

.section-main {
  display: flex;
  flex-direction: row;
  background-color: #fff;
  .graph-side {
    width: 80%;
    &.title-collapse {
      width: 100%;
      transition: all 0.2s linear;
    }
    .card-working {
      position: relative;
      touch-action: pan-x;
      height: calc(100vh - (68px + 66px));
      .toolbar-right-col{
        display: flex;
        .btn-fullscreen{
          margin-left: 10px;
        }
      }
      .card-action {
        .ant-card-body {
          button {
            background: #FFFFFF;
            border: 1px solid rgba(85, 85, 85, 0.5);
            border-radius: 4px;
            padding: 5px 10px;
            font-size: 12px;
            cursor: pointer;
            &:hover {
              span {
                &::before {
                  border-color: inherit;
                }
              }
            }
            &:focus {
              outline: none;
            }
          }
          .button-left {
            display: flex;
            .btn-order-action{
              width: 30px;
              height: 30px;
              padding: 7px;
              img{
                width: 15px;
                height: 15px;
              }
            }
            .btn-select-weight-area{
              position: relative;
            }
            .btn-select-weight{
              height: 30px;
              width: 30px;
              border: 1px solid rgba(85,85,85,.5);
              outline: none;
              border-radius: 4px;
              background: #fff;
              padding: 5px;
              cursor: pointer;
              position: relative;
              margin-right: 10px;
            }
            .btn-weight-line{
              width: 100%;
              height: 2px;
              background: #595959c9;
              position: relative;
              border-radius: 1px;
            }
            .btn-dot-line{
              width: 100%;
              height: 2px;
              position: relative;
              border-top: 2px dotted #595959c9;
              top: 3px;
            }
            .btn-dot-line::before{
              content: '';
              position: absolute;
              display: block;
              width: 100%;
              height: 1.2px;
              background: #595959c9;
              top: -5px;
            }
            .dot-line-option-1{
              width: 100%;
              height: 2px;
              position: relative;
              border-top: 2px dotted #595959c9;
            }
            .dot-line-option-0{
              width: 100%;
              height: 2px;
              position: relative;
              border-top: 2px solid #595959c9;
            }
            .btn-weight-line::before{
              content: '';
              position: absolute;
              display: block;
              width: 100%;
              height: 1.2px;
              background: #595959c9;
              top: -5px;
            }
            .btn-weight-line::after{
              content: '';
              position: absolute;
              display: block;
              width: 100%;
              height: 2.7px;
              background: #595959c9;
              top: 5px;
              border-radius: 1px;
            }
            .line-weight-option{
              padding: 10px 8px;
              border-bottom: 1px solid rgba(85,85,85,.5);
              background-color: #fff;
              cursor: pointer;
            }
            .line-weight-option > div{
              width: 100%;
              background: #595959c9;
            
            }
            .line-weight-select{
              width: 100px;
              position: absolute;
              top: 111%;
              left: 0px;
              height: 0px;
              border: 1px solid rgba(85,85,85,.5);
              border-radius: 4px;
              overflow: hidden;
              border: none;
              box-sizing: border-box;
              z-index: 10;
              transition: all 0.2s linear;
            &.show {
               height: 95px;
               border: 1px solid rgba(85,85,85,.5);
             }
            }
            .dot-line-option{
              padding: 10px 8px;
              border-bottom: 1px solid rgba(85,85,85,.5);
              background-color: #fff;
              cursor: pointer;
            }
            .dot-line-select{
              width: 100px;
              position: absolute;
              top: 111%;
              left: 0px;
              height: 0px;
              border: 1px solid rgba(85,85,85,.5);
              border-radius: 4px;
              overflow: hidden;
              border: none;
              box-sizing: border-box;
              z-index: 10;
              transition: all 0.2s linear;
            &.show {
               height: 43px;
               border: 1px solid rgba(85,85,85,.5);
             }
            }
            button {
              margin-right: 10px;
              padding: 5px 10px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              cursor: pointer;

              &[disabled] {
                cursor: no-drop;

                span {
                  cursor: inherit;
                }
              }

              img {
                margin-right: 5px;
              }

              &.btn-shape {
                img {
                  width: 16px;
                  height: 12px;
                }
              }

              &.btn-connector {
                img {
                  width: 12px;
                  height: 12px;
                }
              }
            }
          }

          .button-right {
            button {
              margin-left: 10px;
              .icon {
                margin-right: 5px;
              }
            }
          }
        }
      }

      .working-part-illus {
        width: 100%;
        height: calc(100vh - 182px - 8px);
        position: relative;
        top: 0;
        background-color: #f0f2f5;
        &.fullscreen-mode{
          height: calc(100vh - 65px);
        }
        .line-vertical {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 50%;
          width: 1px;
          height: 100vh;
          border-left: 1px dashed #2F80ED;
          //z-index: 11;
        }

        .line-horizontal {
          position: absolute;
          top: 50%;
          right: 0;
          bottom: 0;
          left: 0;
          width: 100vw;
          height: 1px;
          border-bottom: 1px dashed #2F80ED;
          //z-index: 11;
        }

        .working-label {
          //padding: 10px;
          display: inline-block;
          position: fixed;
          font-weight: bold;
          z-index: 11;
          // transition: all 0.2s linear;

          &.working-label-1 {
            top: calc(182px + 10px);
            left: 10px;
          }

          &.working-label-2 {
            top: 182px;
            right: 10px;
          }

          &.working-label-3 {
            bottom: 15px;
            left: 10px;
          }

          &.working-label-4 {
            bottom: 15px;
            right: 10px;
          }
        }
      }

      .working-part-graph {
        height: calc(100vh - (68px + 66px + 52px) - 5px);
        width: auto;
        position: absolute;
        top: calc(52px);
        left: 10px;
        right: 0px;
        bottom: 0px;
        //background-color: #f8f9fa;
        overflow: scroll;
        &.fullscreen-mode{
          height: calc(100vh - 54px);
        }

        .graphContainer {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          top: 0;
          //background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PHBhdHRlcm4gaWQ9ImdyaWQiIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgcGF0dGVyblVuaXRzPSJ1c2VyU3BhY2VPblVzZSI+PHBhdGggZD0iTSAwIDQgTCAxNiA0IE0gNCAwIEwgNCAxNiBNIDAgOCBMIDE2IDggTSA4IDAgTCA4IDE2IE0gMCAxMiBMIDE2IDEyIE0gMTIgMCBMIDEyIDE2IiBmaWxsPSJub25lIiBzdHJva2U9IiNkMGQwZDAiIG9wYWNpdHk9IjAuMiIgc3Ryb2tlLXdpZHRoPSIxIi8+PHBhdGggZD0iTSAxNiAwIEwgMCAwIDAgMTYiIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2QwZDBkMCIgc3Ryb2tlLXdpZHRoPSIxIi8+PC9wYXR0ZXJuPjwvZGVmcz48cmVjdCB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiBmaWxsPSJ1cmwoI2dyaWQpIi8+PC9zdmc+);
          background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PHBhdHRlcm4gaWQ9ImEiIHdpZHRoPSI0MCIgaGVpZ2h0PSI0MCIgcGF0dGVyblVuaXRzPSJ1c2VyU3BhY2VPblVzZSI+PHBhdGggZD0iTTAgMTBoNDBNMTAgMHY0ME0wIDIwaDQwTTIwIDB2NDBNMCAzMGg0ME0zMCAwdjQwIiBmaWxsPSJub25lIiBzdHJva2U9IiNlMGUwZTAiIG9wYWNpdHk9Ii4yIi8+PHBhdGggZD0iTTQwIDBIMHY0MCIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZTBlMGUwIi8+PC9wYXR0ZXJuPjwvZGVmcz48cmVjdCB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiBmaWxsPSJ1cmwoI2EpIi8+PC9zdmc+);
          //background-color: #fff;
          width: 100%;
          height: 100%;
          .shapeWrapper {
            .shapeTitle {
              white-space: pre;
            }
          }
        }
      }
    }
  }

  .title-side {
    display: flex;
    flex-direction: column-reverse;
    width: 20%;
    &.title-collapse {
      position: absolute;
      bottom: 0;
      right: 0;
    }
    .section-side {
      border-radius: 0;
      z-index: 12;

      .ant-collapse-item {
        border: 1px solid #A7A7A7;
        .ant-collapse-header {
          background: #F9F9F9;
          color: rgba(85, 85, 85, 0.5);
          font-size: 12px;
          font-weight: bold;
          padding: 0;
          display: flex;
          justify-content: space-between;

          .panel-title {
            padding: 10px 20px;
            width: 100%;
          }

          .ant-collapse-extra {
            position: relative;

            .ant-btn {
              position: absolute;
              right: 0;
              top: 0;
              padding: 12px;
              height: 42px;
              font-weight: inherit;
              font-size: 15px;
              border: none;
              background: none;

              &:hover {
                color: #1890ff;
              }

              &:focus {
                box-shadow: none;
              }
            }
          }
        }
      }

      .ant-collapse-content {
        border-top: 0;
        //height: 300px;
        overflow-y: auto;

        .ant-collapse-content-box {
          padding: 0;
          height: 100%;

          .ant-card {
            border: none;
            height: 100%;

            .ant-card-body {
              padding: 0;
              height: 100%;

              textarea {
                border: none !important;
                padding: 20px;
                resize: none;
                height: 100%;

                &:focus {
                  box-shadow: none;
                }

                &[disabled] {
                  background: none;
                  cursor: default;
                }
              }
            }
          }

          .card-guess {

            .ant-list-item {
              border-collapse: separate;
              border-spacing: 0 1em;
              border-bottom: none;
              padding: 10px 10px 0 10px;

              &:last-child {
                padding-bottom: 10px;
              }

              .ant-list-item-meta {
                border: 1px solid #AAAAAA;
                border-radius: 10px;
                padding: 10px;
                cursor: pointer;

                .ant-list-item-meta-title {
                  margin: 0;
                  width: 100%;
                  white-space: pre-line;
                  word-break: break-all;
                }
              }
            }
          }
        }
      }
    }
  }
}

.modal-slide {
  .ant-modal-body {
    .ant-form-item {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .ant-form-item-label {
    text-align: left;

    label {
      &::before {
        content: '';
        margin-right: 0;
      }

      &::after {
        content: '';
      }

      &.ant-form-item-required {
        &::after {
          display: inline-block;
          color: #f5222d;
          margin-left: 4px;
          font-size: 14px;
          font-family: SimSun, sans-serif;
          line-height: 1;
          content: '*';
        }
      }
    }
  }

  .ant-modal-footer {
    border-top: none;
    padding: 20px;

    .btn-delete {
      float: left;
    }
  }
}

//custom scrollbar
::-webkit-scrollbar-track {
  background-color: #fff;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #d4d4d4;
  border-radius: 6px;

  &:hover {
    background: #9c9c9c;
  }
}

.lbexpopup,
.mxTooltip {
  display: none;
}

.color-selector-area {
  display: flex;
  .color-picker {
    display: flex;
    position: absolute;
    z-index: 1;
    top: 32px;
    height: 0;
    padding: 0;
    overflow: hidden;
    transition: all 0.2s linear;
    &.show {
      height: 32px;
      padding: 1px;
    }
    .color-element {
      cursor: pointer;
      width: 40px;
      height: 30px;
      outline: solid 1px #000;
    }
  }
}
