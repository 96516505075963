.loading-overlay-1{
    position: fixed;
    background-color: rgba(255, 255, 255, 0.8);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    .loading-spin{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }
}