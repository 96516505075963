.arrow-selector {
  display: flex;
  flex-direction: column;
}
.arrow-button {
  border: none;
  border-radius: 0;
}

.arrow-button:hover, .arrow-button.active {
  background-color: #40a9ff;
}
