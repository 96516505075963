@import "~antd/dist/antd.css";

body {
  font-family: system-ui;
  font-style: normal;
  background: #f5f5f5;
  font-size: 16px;
  color: #555555;
}

.logo {
  /* font-weight: bold; */
  font-size: 35px;
  line-height: 70px;
  text-align: center;
  color: #cbcbcb;
  margin-top: 40px;
}

.text-slide {
  color: #2f80ed;
}

.container {
  text-align: center;
  margin: auto;
  width: 30%;
  min-width: 500px;
}

.block-content {
  background: #ffffff;
}

.title {
  font-weight: bold;
  font-size: 32px;
  line-height: 46px;
  color: #555555;
  padding: 30px;
}

.login-form {
  text-align: left;
}

.ant-input {
  background: #ffffff;
  border: 1px solid rgba(85, 85, 85, 0.5);
  box-sizing: border-box;
  border-radius: 4px;
  height: 44px;
}

.ant-input:hover {
  border: 2px solid #555555 !important;
}

.login-form-forgot {
  float: right;
}

.button-form {
  width: 100%;
  background: #2f80ed;
  height: 44px;
}

.google-login {
  margin-bottom: 20px;
}
.login-content {
  background-color: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -90%);
  box-sizing: border-box;
  max-width: 554px;
  width: 100%;
  .login-content__title {
    padding: 30px 0px;
    color: #555;
  }
  .btn-login {
    height: 42px;
    width: 160px;
    margin-bottom: 20px;
  }
  img {
    position: absolute;
    &[alt="login image text"] {
      width: 240px;
      top: -90px;
      left: 50%;
      transform: translateX(-50%);
    }
    &[alt="login image 1"] {
      width: 52px;
      left: -21px;
      top: -30px;
    }
    &[alt="login image 2"] {
      width: 60px;
      right: -20px;
      bottom: -21px;
    }
    &[alt="login image 3"] {
      width: 400px;
      top: -270px;
      right: -130px;
    }
    &[alt="login image 4"] {
      width: 260px;
      top: 115%;
      right: -60px;
    }
    &[alt="login image 5"] {
      width: 140px;
      left: 0;
      bottom: 100%;
    }
    &[alt="login image 6"] {
      width: 200px;
      left: -120px;
      top: 100%;
    }
  }
}
@media screen and (max-width: 1600px) {
  .login-content {
    transform: translate(-50%, -50%);
  }
}

@media screen and (max-width: 1366px) {
  .login-content {
    transform: translate(-50%, -50%);
    img {
      position: absolute;
      &[alt="login image text"] {
        width: 220px;
        top: -80px;
        left: 50%;
        transform: translateX(-50%);
      }
      &[alt="login image 1"] {
        width: 42px;
        left: -21px;
        top: -22px;
      }
      &[alt="login image 2"] {
        width: 46px;
        right: -26px;
        bottom: -21px;
      }
      &[alt="login image 3"] {
        width: 320px;
        top: -220px;
        right: -110px;
      }
      &[alt="login image 4"] {
        width: 220px;
        top: 115%;
        right: -60px;
      }
      &[alt="login image 5"] {
        width: 106px;
        left: 0;
        bottom: 100%;
      }
      &[alt="login image 6"] {
        width: 158px;
        left: -96px;
        top: 99%;
      }
    }
  }
}
