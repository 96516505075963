.fullscreen__container{
    height: 100%;
    width: 100%;
    background-color: #fff;
    &.full{
        position: fixed;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        z-index: 20;
        & .top-control{
            display: block;
        }
        & .bottom-control{
            display: block;
        }
    }
    & .top-control{
        position: absolute;
        top: 44px;
        right: 20px;
        display: none;
    }
    & .bottom-control{
        position: absolute;
        bottom: 20px;
        right: 20px;
        display: none;
    }
}

.ml-1{
    margin-left: 10px;
}
@media print {
    .fullscreen__container{
        & .top-control{
           display: none;
        }
        & .bottom-control{
            display: none;
        }
        &.full{
            & .top-control{
                display: none;
            }
            & .bottom-control{
                display: none;
            }
        }
    }
    html, body {
        height:100%; 
        margin: 0 !important; 
        padding: 0 !important;
        overflow: hidden;
    }
}
