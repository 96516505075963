@import '~antd/dist/antd.css';

.slide-search {
  margin: 20px;
}

.slide-content {
  background: #FFFFFF;
  text-align: center;
  margin: auto;
  width: 65%;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .slide-content {
    width: 94%;
  }
  .search-button {
    width: 300px !important;
  }
}

.welcome-content {
  color: #4E80ED;
  font-weight: 500;
  font-size: 20px;
  /* line-height: 29px; */
  margin: 20px;
  padding: 10px;
  background: #EAF2FD;
}

.welcome-content-left , .welcome-content-left a{
  text-align: left;
  color: #4E80ED;
}

.add-slide-button {
  float: left;
  height: 44px;
}

.search-button {
  float: right;
  height: 44px;
  width: 400px;
} 

.slide-paginate {
  background: #F5F5F5;
  padding: 20px;
}

.ant-pagination li {
  height: 45px;
  min-width: 45px;
  line-height: 45px;
  background: #fff;
}

.ant-pagination-prev {
  border: 1px solid #d9d9d9;
}

.ant-pagination-next {
  border: 1px solid #d9d9d9;
}

.ant-pagination-disabled {
  background: #F5F5F5 !important;
}
