@import '~antd/dist/antd.css';

.slide-header {
  margin: auto;
  width: 65%;
  padding: 10px 0;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .slide-header {
    width: 94%;
  }
}

.homepage {
  margin-bottom: 0;
  color: #cbcbcb;
}

.homepage:hover {
  cursor: pointer;
}

.homepage-title {
  margin-bottom: 0;
  color: #555555;
  font-size: 24px;
  font-weight: bold;
}

.username {
  margin-right: 10px;
}