.control-canvas-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .btn-control-container {
    background-color: #fff;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    position: relative;
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.2);
    margin-bottom: 12px;
  }
  
  .btn-control-container .control-left {
    position: absolute;
    top: 22px;
    left: 1px;
    cursor: pointer;
  }
  
  .control-right {
    position: absolute;
    top: 22px;
    right: 1px;
    cursor: pointer;
  }
  
  .control-down {
    position: absolute;
    bottom: 1px;
    left: 22px;
    cursor: pointer;
  }
  .control-up {
    position: absolute;
    top: 1px;
    left: 22px;
    cursor: pointer;
  }
  .btn-zoom-container {
    display: flex;
    flex-direction: column;
    width: 30px;
    border-radius: 4px;
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.2);
  }
  .btn-zoom-in {
    cursor: pointer;
    background-color: #fff;
    border: 1px solid #eee;
    background-color: #fff;
    border: 1px solid #eee;
  }
  .btn-zoom-out {
    cursor: pointer;
    width: 30px;
    height: 30px;
    background-color: #fff;
    border: 1px solid #eee;
  }
  .btn-undo{
    background-color: #fff;
    border: 1px solid #eee;
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.2);
    margin-top: 12px;
    border-radius: 4px;
    cursor: pointer;
  }